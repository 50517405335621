import React from 'react';
import Layout from '../components/Layout';

function LaunchThankYou() {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <div className="columns is-centered">
              <div className="column is-full">
                <h1 className="title is-size-2 is-family-secondary has-text-info has-text-centered">
                  Thank you for joining the launch team!
                </h1>
                <p className="is-size-5 has-text-centered">We will email you soon!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default LaunchThankYou;
